import garden from '../assets/garden.svg';
import icon3d from '../assets/3d.svg';
import architecturePlan from '../assets/architecture-plan.svg';
import floor from '../assets/floor.svg';

export default function offer() {
	return (
		<section className="offer-section" id="offer">
			<div className="flex-column flex-centered">
				<h2>Oferta</h2>
				<hr />
				<div className="offer--container">
					<div className="offer--single flex-column">
						<img src={garden} alt="" className="offer--icon" />
						<p>Projekty ogrodów i&nbsp;zieleni publicznej</p>
					</div>
					<div className="offer--single flex-column">
						<img src={icon3d} alt="" className="offer--icon" />
						<p>Wizualizacje 3D</p>
					</div>
					<div className="offer--single flex-column">
						<img
							src={architecturePlan}
							alt=""
							className="offer--icon"
						/>
						<p>Projekty małej architektury</p>
					</div>
					<div className="offer--single flex-column">
						<img src={floor} alt="" className="offer--icon" />
						<p>Projekty nawierzchni, oświetlenia, nawodnienia</p>
					</div>
				</div>
			</div>
		</section>
	);
}
