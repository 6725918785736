import mailIcon from "../assets/mail.png";
import treeIcon from "../assets/tree.png";
import checkMarkIcon from "../assets/check-mark.png";
import sketchIcon from "../assets/sketch.png";

export default function Stages() {
	return (
		<section className="stages-section">
			<div className="flex-column flex-centered">
				<h2>Proces projektowy</h2>
				<hr />
				<div className="stages--container">
					<div className="stages--single">
						<div className="stages--circle flex-column">
							<p className="stages--title">Oferta</p>
							<img src={mailIcon} alt="" className="stages--icon" id="mailIcon"/>
						</div>
						<div className="stages--text-container">
							<p className="stages--text">
								Każda oferta przygotowywana jest
								indywidualnie.
								Wycena jest darmowa
								i&nbsp;niezobowiązująca.
							</p>
						</div>
					</div>
					<div className="stages--single">
						<div className="stages--circle flex-column">
							<p className="stages--title">Koncepcja</p>
							<img src={sketchIcon} alt="" className="stages--icon"/>
						</div>
						<div className="stages--text-container">
							<p className="stages--text">
								Projekt koncepcyjny
								wykonywany jest na
								podstawie uzyskanych
								wcześniej informacji.
								Otrzymasz rzut ogrodu
								ze zdjęciami
								inspiracyjnymi.
							</p>
						</div>
					</div>
					<div className="stages--single">
						<div className="stages--circle flex-column">
							<p className="stages--title">Wizualizacje</p>
							<img src={treeIcon} alt="" className="stages--icon"/>
						</div>
						<div className="stages--text-container">
							<p className="stages--text">
								Realistyczne ujęcia ogrodu
								przedstawione są po
								akceptacji końcowej wersji
								projektu koncepcyjnego.
								Wizualizacje sprawią, że
								zobaczysz jak będzie
								wyglądał&nbsp;w przyszłości ogród
								Twoich marzeń.
							</p>
						</div>
					</div>
					<div className="stages--single">
						<div className="stages--circle flex-column">
							<p className="stages--title">Gotowy projekt</p>
							<img src={checkMarkIcon} alt="" className="stages--icon"/>

						</div>
						<div className="stages--text-container">
							<p className="stages--text">
								Końcowy etap składa się z&nbsp;projektów
								technicznych, doboru roślin oraz
								materiałów wraz z&nbsp;zapotrzebowaniem.
								Projekt czekać będzie na Ciebie
								również w wersji drukowanej!
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}