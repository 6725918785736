export default function Header(props) {
	function scrollTo(e, element) {
		console.log(e);
		e.preventDefault();
		window.scroll({
			behavior: 'smooth',
			left: 0,
			top: element.offsetTop - 100,
		});
	}

	return (
		<header>
			<div className="logo-container">
				<a href="/" className="logo-link">
					<img
						src={require('../assets/logo-white.png')}
						alt="mw-studio-projektowe"
						className="header--logo"
					/>
				</a>
				<a href="/" className="logo-link">
					<p>MW STUDIO PROJEKTOWE</p>
				</a>
			</div>
			<nav className="nav-container">
				<ul>
					<li>
						<button
							onClick={(e) =>
								scrollTo(e, document.getElementById('root'))
							}
						>
							HOME
						</button>
					</li>
					<li>
						<button
							onClick={(e) =>
								scrollTo(e, document.getElementById('about'))
							}
						>
							O MNIE
						</button>
					</li>
					<li>
						<button
							onClick={(e) =>
								scrollTo(e, document.getElementById('projects'))
							}
						>
							PORTFOLIO
						</button>
					</li>
					<li>
						<button
							onClick={(e) =>
								scrollTo(e, document.getElementById('offer'))
							}
						>
							OFERTA
						</button>
					</li>
					<li>
						<button
							onClick={(e) =>
								scrollTo(e, document.getElementById('contact'))
							}
						>
							KONTAKT
						</button>
					</li>
				</ul>
			</nav>
		</header>
	);
}
