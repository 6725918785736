import Header from './Header';
import About from './About';
import Stages from './Stages';
import Offer from './Offer';
import Contact from './Contact';
import ProjectDemo from './ProjectDemo';
import Hero from './Hero';

export default function Home(props) {
	const projectDemoElements = [];
	props.data.forEach((item) => {
		projectDemoElements.push(
			<ProjectDemo
				title={item.name}
				loc={item.loc}
				imgs={item.imgs}
				id={item.id}
				key={item.id}
				img={`${item.id}${item.imgs[0]}`}
				img2={`${item.id}${item.imgs[1]}`}
			/>
		);
	});

	return (
		<>
			<Header />
			<main>
				<Hero />
				<About />
				<section className="projects-section" id="projects">
					<div className="flex-column flex-centered">
						<h2>Portfolio</h2>
						<hr />
					</div>
					<div className="projects flex-centered">
						{projectDemoElements}
					</div>
				</section>
				<Stages />
				<Offer />
				<Contact />
			</main>
		</>
	);
}
