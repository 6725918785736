import React from 'react';
import HeaderSecondary from './HeaderSecondary';

export default function Project(props) {
	const photoElements = props.imgs.map((img) => {
		return (
			<img
				src={require(`../assets/vis-lowres/${props.id}${img}.jpg`)}
				alt="3d render"
				className="project-page--img"
				key={img}
			/>
		);
	});

	return (
		<>
			<HeaderSecondary />
			<div className="project-page">
				<div className="flex-column flex-centered">
					<h2>{props.title}</h2>
					<h3>{props.loc}</h3>
					<hr />
				</div>
				<div className="project-page--photos flex-centered">
					{photoElements}
				</div>
			</div>
		</>
	);
}
