export const projectsData = [
	{
		id: 'borkowo',
		name: '',
		loc: 'Borkowo',
		imgs: [1, 2, 3, 4, 5, 6, 7],
	},
	{
		id: 'gdansk',
		name: '',
		loc: 'Gdańsk',
		imgs: [1, 2, 3, 4],
	},
	{
		id: 'gdynia',
		name: '',
		loc: 'Gdynia',
		imgs: [1, 2, 3, 4, 5, 6, 7, 8],
	},
	{
		id: 'lodz',
		name: '',
		loc: 'Łódź',
		imgs: [1, 2, 3, 4, 5, 6, 7],
	},
	{
		id: 'pradowka',
		name: '',
		loc: 'Prądówka',
		imgs: [1, 2, 3, 4, 5, 6],
	},
	{
		id: 'swieciechowa',
		name: '',
		loc: 'Święciechowa',
		imgs: [1, 2, 3, 4],
	},
	{
		id: 'zbrudzewo',
		name: 'Ogród klasyczny',
		loc: 'Zbrudzewo',
		imgs: [1, 2, 3, 4],
	},
	{
		id: 'gowarzewo',
		name: 'Mały ogród z pergolą',
		loc: 'Gowarzewo',
		imgs: [2, 3],
	},
	{
		id: 'skrzynki',
		name: 'Minimalistyczny ogród z jacuzzi',
		loc: 'Skrzynki',
		imgs: [1, 2, 3, 4],
	},
	{
		id: 'chotomow',
		name: 'Ogród minimalistyczny',
		loc: 'Chotomów',
		imgs: [1, 2, 3, 4],
	},
	{
		id: 'mechelinki',
		name: 'Ogród nadmorski',
		loc: 'Mechelinki',
		imgs: [1, 2, 3, 4, 5],
	},
	{
		id: 'pokrzywnica',
		name: 'Ogród z paleniskiem',
		loc: 'Pokrzywnica',
		imgs: [1, 2, 3, 4],
	},
	{
		id: 'lusowo',
		name: 'Tarasowy ogród',
		loc: 'Lusowo',
		imgs: [1, 2, 3, 4, 5],
	},
];
