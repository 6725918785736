import { Link } from 'react-router-dom';

export default function ProjectDemo(props) {
	return (
		<div className="project-container flex-column">
			<div className="flex-column">
				<h3>{props.loc}</h3>
			</div>
			<Link to={`/projekty/${props.id}`}>
				<div className="container relative project-container">
					<img
						src={require(`../assets/vis-lowres/${props.img}.jpg`)}
						alt=""
						className="project--img"
					/>
					<div className="project--enter">
						<img
							src={require(`../assets/vis-lowres/${props.img2}.jpg`)}
							alt=""
							className="project--img"
						/>
					</div>
				</div>
			</Link>
		</div>
	);
}
