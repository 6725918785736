import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Project from './components/Project';
import ScrollToTop from './components/ScrollToTop';
import { projectsData } from './components/projectsData';

function App() {
	const routesElements = projectsData.map((p) => {
		return (
			<Route
				path={`projekty/${p.id}`}
				element={
					<Project
						id={p.id}
						title={p.name}
						loc={p.loc}
						imgs={p.imgs}
					/>
				}
				loc={p.loc}
				key={p.id}
			/>
		);
	});

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home data={projectsData} />} />
				{routesElements}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
