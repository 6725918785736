import React from 'react';

export default function ContactForm() {
	const [inputValues, setInputValues] = React.useState({
		name: '',
		email: '',
		message: '',
	});

	function handleChange(e) {
		console.log(e.target.name);
		setInputValues((prevValues) => {
			return {
				...prevValues,
				[e.target.name]: e.target.value,
			};
		});
	}

	return (
		<div className="contact--right">
			<p className="contact--title">Zostaw wiadomość</p>
			<form
				action="https://formsubmit.co/mw.studioprojektowe@gmail.com"
				method="POST"
			>
				<div className="flex-row inputs-container">
					<div className="cell">
						<input
							type="text"
							id="contact--name-input"
							name="name"
							placeholder=" "
							maxLength={30}
							value={inputValues.name}
							onChange={handleChange}
						/>
						<label htmlFor="contact--name-input">Imię</label>
					</div>
					<div className="cell">
						<input
							type="email"
							id="contact--email-input"
							name="email"
							placeholder=" "
							maxLength={50}
							value={inputValues.email}
							onChange={handleChange}
							required
						/>
						<label htmlFor="contact--email-input">Email</label>
					</div>
				</div>
				<div className="flex-row">
					<div className="cell">
						<textarea
							id="contact--msg-input"
							placeholder=" "
							name="message"
							maxLength={1000}
							value={inputValues.message}
							onChange={handleChange}
							required
						/>
						<label htmlFor="contact--msg-input">Wiadomość</label>
					</div>
				</div>
				<button className="form--submit-btn">Wyślij wiadomość</button>
			</form>
		</div>
	);
}
