import ContactCard from './ContactCard';
import ContactForm from './ContactForm';

export default function Contact() {
	return (
		<section className="contact" id="contact">
			<ContactCard />
			<ContactForm />
		</section>
	);
}
