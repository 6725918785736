import '../carousel.css';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function Hero() {
	return (
		<div className="hero">
			<Carousel infiniteLoop={true} showStatus={false} showThumbs={false}>
				<div>
					<img src={require('../assets/1.jpg')} alt="" />
				</div>
				<div>
					<img src={require('../assets/2.jpg')} alt="" />
				</div>
				<div>
					<img src={require('../assets/3.jpg')} alt="" />
				</div>
			</Carousel>
		</div>
	);
}
