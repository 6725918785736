import { Link } from 'react-router-dom';

export default function HeaderSecondary() {
	return (
		<header className="header-secondary">
			<Link to={'/'}>
				<div className="logo-container">
					<div className="logo-link">
						<img
							src={require('../assets/logo-white.png')}
							alt="mw-studio-projektowe"
							className="header--logo"
						/>
					</div>
					<div className="logo-link">
						<p>MW STUDIO PROJEKTOWE</p>
					</div>
				</div>
			</Link>
			<nav className="nav-container">
				<ul>
					<li>
						<Link to={'/'}>
							<button>HOME</button>
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
}
