export default function About() {
	return (
		<section className="about-section" id="about">
			<div className="flex-column flex-centered">
				<h2>O mnie</h2>
				<hr />
			</div>
			<div className="about">
				<div className="about--text">
					<p>
						Nazywam się Martyna Wiśniewska. <br />
						Jestem architektem krajobrazu z&nbsp;ogromną pasją do
						zieleni. Projektowanie ogrodów, tarasów i&nbsp;balkonów
						to moja specjalność. Słuchając klienta wychodzę
						naprzeciw jego oczekiwaniom, a&nbsp;zastosowane
						rozwiązania są funkcjonalne i&nbsp;estetyczne.
					</p>
					<p>
						Jeśli zależy Ci na ogrodzie dostosowanym do Twoich
						potrzeb, to trafiłeś w&nbsp;odpowiednie miejsce.
					</p>
					<p>
						Województwo pomorskie, kujawsko-pomorskie oraz
						wielkopolskie to główne obszary mojego działania. Nic
						nie stoi na przeszkodzie, aby nawiązać współpracę
						również z klientami z innych rejonów Polski.
					</p>
				</div>
				<img
					src={require("../assets/PhotoHiRes.JPEG")}
					alt=""
					className="about--photo"
				/>
			</div>
		</section>
	);
}
