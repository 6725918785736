import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles.scss';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDO3M2SWNB8CvetLIESgO8PAmmniYzzqS8',
	authDomain: 'mw-studio-ecafc.firebaseapp.com',
	projectId: 'mw-studio-ecafc',
	storageBucket: 'mw-studio-ecafc.appspot.com',
	messagingSenderId: '922755410655',
	appId: '1:922755410655:web:e8b580339494d82ef9576a',
	measurementId: 'G-T0M95FKBYM',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
