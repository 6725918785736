export default function ContactCard() {
	return (
		<div className="contact--left">
			<p className="contact--title">Kontakt</p>
			<p className="contact--info contact--noicon">
				Martyna Wiśniewska Studio&nbsp;Projektowe
			</p>
			<p className="contact--info contact--noicon">NIP: 5891881601</p>
			<div className="flex-row">
				<img
					src={require('../assets/pin-white-32.ico')}
					alt="email"
					className="icon"
				/>
				<a
					className="contact--info"
					href="https://goo.gl/maps/MjsKd2r8Dtineuw26"
				>
					ul. Księżycowa 41, 80-297 Banino
					<img
						src={require('../assets/google-maps-icon.png')}
						alt="google maps"
						className="icon google-maps"
					/>
				</a>
			</div>
			<div className="flex-row">
				<img
					src={require('../assets/mail-white-32.ico')}
					alt="email"
					className="icon"
				/>
				<p className="contact--info">mw.studioprojektowe@gmail.com</p>
			</div>
			<div className="flex-row">
				<img
					src={require('../assets/phone-white-32.ico')}
					alt="phone"
					className="icon"
				/>
				<p className="contact--info">+48 731429971</p>
			</div>
			<img
				src={require('../assets/logo-white2.png')}
				alt="mw studio projektowe"
				className="logo"
			/>
			<div className="flex-row social-links">
				<a href="https://www.instagram.com/mw.studioprojektowe/?fbclid=IwAR1LD5iEok9F2VFnnukFUH8FJHqD-3pw_I7d0p4nCWO8Rb3eK1fRmFB7Jnw">
					<img
						src={require('../assets/instagram-logo2.png')}
						alt="instagram"
						className="icon-social"
					/>
				</a>
				<a href="https://www.facebook.com/MW-Studio-Projektowe-106761418662254/?ref=page_internal">
					<img
						src={require('../assets/facebook-logo.png')}
						alt="facebook"
						className="icon-social"
					/>
				</a>
			</div>
		</div>
	);
}
